import * as React from "react";
import { Link } from "gatsby";

import Ch from "../../components/Chord.js";
import Chorus from "../../components/Chorus.js";
import Columns from "../../components/Columns.js";
import { Helmet } from "react-helmet";
import Layout from "../../components/Layout";
import PreChorus from "../../components/PreChorus.js";
import Verse from "../../components/Verse.js";

export default function IfYouCouldOnlySee() {
  return (
    <Layout>
      <Helmet title="If You Could Only See | Tonic" />

      <h1>If You Could Only See</h1>

      <h2>Tonic</h2>

      <h4>Key: G</h4>

      <h4>Tempo: 92</h4>

      <Link to="/">List</Link>

      <Columns columns="2">
        <Chorus>
          <p>
            If you could only <Ch>Am</Ch>see the way she <Ch>C</Ch>loves me
          </p>
          <p>
            Then maybe you would <Ch>G</Ch>understand
          </p>
          <p>
            Why I feel this <Ch>Am</Ch>way about our <Ch>C</Ch>love
          </p>
          <p>
            And what I must <Ch>G</Ch>do
          </p>
          <p>
            If you could only <Ch>Am</Ch>see how blue
          </p>
          <p>
            Her <Ch>C</Ch>eyes can be when she <Ch>G</Ch>says
          </p>
          <p>
            <Ch>F</Ch>When she says she loves me
          </p>
        </Chorus>
        <PreChorus>
          <p>
            <b>Am Em G F (x2)</b>
          </p>
        </PreChorus>
        <Verse>
          <p>
            <Ch>Am</Ch>Well you got your reasons
          </p>
          <p>
            <Ch>F</Ch>And you got your <Ch>D</Ch>lies
          </p>
          <p>
            <Ch>Am</Ch>And you got your manipulations
          </p>
          <p>
            <Ch>F</Ch>They cut me down to <Ch>D</Ch>size
          </p>
        </Verse>
        <PreChorus>
          <p>
            <Ch>Am</Ch>Say you love but <Ch>C</Ch>you don't
          </p>
          <p>
            You give your love but <Ch>G</Ch>you won't
          </p>
        </PreChorus>
        <Chorus>
          <p>
            If you could only <Ch>Am</Ch>see the way she <Ch>C</Ch>loves me
          </p>
          <p>
            Then maybe you would <Ch>G</Ch>understand
          </p>
          <p>
            Why I feel this <Ch>Am</Ch>way about our <Ch>C</Ch>love
          </p>
          <p>
            And what I must <Ch>G</Ch>do
          </p>
          <p>
            If you could only <Ch>Am</Ch>see how blue
          </p>
          <p>
            Her <Ch>C</Ch>eyes can be when she <Ch>G</Ch>says
          </p>
          <p>
            <Ch>F</Ch>When she says she loves me
          </p>
        </Chorus>
        <PreChorus>
          <p>
            <b>Am Em G F (x2)</b>
          </p>
        </PreChorus>
        <Verse>
          <p>
            <Ch>Am</Ch>Seems the road less traveled
          </p>
          <p>
            <Ch>F</Ch>Shows happiness un<Ch>D</Ch>raveled
          </p>
          <p>
            <Ch>Am</Ch>And you got to take a little dirt
          </p>
          <p>
            To keep what you <Ch>F</Ch>love
          </p>
          <p>
            <Ch>D</Ch>That's what you gotta do
          </p>
        </Verse>
        <PreChorus>
          <p>
            <Ch>Am</Ch>Say you love but <Ch>C</Ch>you don't
          </p>
          <p>
            You give your love but <Ch>G</Ch>you won't
          </p>
          <p>
            <small>You're stretching out your arms</small>
          </p>
          <p>
            <small>To something that's just not there</small>
          </p>
          <p>
            <Ch>Am</Ch>Say you love where <Ch>C</Ch>you stand
          </p>
          <p>
            Give your heart <Ch>G</Ch>when you can<Ch>F</Ch>
          </p>
        </PreChorus>
        <Chorus>
          <p>
            If you could only <Ch>Am</Ch>see the way she <Ch>C</Ch>loves me
          </p>
          <p>
            Then maybe you would <Ch>G</Ch>understand
          </p>
          <p>
            Why I feel this <Ch>Am</Ch>way about our <Ch>C</Ch>love
          </p>
          <p>
            And what I must <Ch>G</Ch>do
          </p>
          <p>
            If you could only <Ch>Am</Ch>see how blue
          </p>
          <p>
            Her <Ch>C</Ch>eyes can be when she <Ch>G</Ch>says
          </p>
          <p>
            <Ch>F</Ch>When she says she loves me
          </p>
        </Chorus>
        <Verse>
          <p>(Solo)</p>
          <p>
            <b>Am Em G F (x2)</b>
          </p>
          <p>
            <b>Am F D (x2)</b>
          </p>
        </Verse>
        <PreChorus>
          <p>
            <Ch>Am</Ch>Say you love but <Ch>C</Ch>you don't
          </p>
          <p>
            You give your love but <Ch>G</Ch>you won't
          </p>
          <p>
            <Ch>Am</Ch>Say you love where <Ch>C</Ch>you stand
          </p>
          <p>
            Give your heart <Ch>G</Ch>when you can<Ch>F</Ch>
          </p>
        </PreChorus>
        <Chorus>
          <p>
            If you could only <Ch>Am</Ch>see the way she <Ch>C</Ch>loves me
          </p>
          <p>
            Then maybe you would <Ch>G</Ch>understand
          </p>
          <p>
            Why I feel this <Ch>Am</Ch>way about our <Ch>C</Ch>love
          </p>
          <p>
            And what I must <Ch>G</Ch>do
          </p>
          <p>
            If you could only <Ch>Am</Ch>see how blue
          </p>
          <p>
            Her <Ch>C</Ch>eyes can be when she <Ch>G</Ch>says
          </p>
          <p>
            <Ch>F</Ch>When she says she loves me
          </p>
        </Chorus>
      </Columns>
    </Layout>
  );
}
